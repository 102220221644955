import Cookies from 'js-cookie'
import i18n from '@/locales/index.js'
const Key = 'jb_lang'

export function get() {
  return Cookies.get(Key)
}

export function set(data) {
  i18n.locale = data
  return Cookies.set(Key, data)
}

export function remove() {
  return Cookies.remove(Key)
}