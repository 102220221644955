import axios from 'axios'
// import { Message } from 'element-ui'
// import { baseURL } from '@/config'
let base_url;
if (process.env.NODE_ENV === 'production') {
  base_url = 'https://www.renrenbl.com/api/front'
} else if (process.env.NODE_ENV === 'development') {
  base_url = 'http://localhost:3000/api/front'
}
// create an axios instance
console.log(base_url)
const service = axios.create({
  baseURL: base_url, // api 的 base_url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // if(response.code != 200){
    //   Toast(response.msg)
    // }
    if (response.status === 200) {
      const { data } = response
      return data
    } else{
      throw new Error('网络错误')
    }
  },
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
