<template>
  <div class="container">
    <com-header />
    <div class="title">
      <h1>{{$t("rank_title")}}</h1>
      <div>{{$t("rank_slogen")}}</div>
    </div>
    <div class="main">
      <div class="tableHeader">
        <span class="rank">{{$t('home_rank')}}</span>
        <span class="nick">{{$t("rank_nikename")}}</span>
        <span class="num">{{$t("rank_num")}}</span>
        <span class="bonus">{{$t("rank_rewards")}}</span>
      </div>
      <div v-if="rank.length > 0">
        <div class="tableItem" v-for="(item, index) in rank" :key="index">
          <span class="rank"
            ><img v-if="index + 1 <= 3" :src="rankImg[index + 1]" alt="" /><span
              v-if="index + 1 > 3"
              >{{ index === 9 ? "" : "0" }}{{ index + 1 }}</span
            ></span
          >
          <span class="nick">{{ item.nickName }}</span>
          <span class="num">{{ item.invitationNum }}</span>
          <span class="bonus">{{ item.invitationBonus }} RMB</span>
        </div>
      </div>
      <el-empty v-else :image-size="100"></el-empty>
    </div>
  </div>
</template>

<script>
import { getRankList } from "@/api";
import header from "@/components/header.vue";
import rank1 from "@/assets/img/01.png";
import rank2 from "@/assets/img/02.png";
import rank3 from "@/assets/img/03.png";
export default {
  data() {
    return {
      rank: [],
      rankImg: {
        1: rank1,
        2: rank2,
        3: rank3,
      },
    };
  },
  methods: {
    getData() {
      getRankList().then((res) => {
        if (res.code === 200) {
          const data = res.data.sort((a, b) => a.rank - b.rank);
          this.rank = data;
        }
      });
    },
  },
  components: {
    ComHeader: header,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.container {
  background-image: url(../assets/img/home-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12% 120px 82px;
  .title {
    color: #ffffff;
    margin: 0px 0 37px 0;
    position: relative;
    h1 {
      font-weight: 600;
      font-size: 34px;
      line-height: 48px;
    }
    div {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
  .main {
    padding: 40px;
    background: #ffffff;
    border-radius: 16px;
    .rank {
      font-size: 16px;
      line-height: 24px;
      color: #97a1a9;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .nick {
      text-align: left;
    }
    .num {
      text-align: left;
    }
    .bonus {
      text-align: right;
    }
    .tableHeader {
      font-size: 14px;
      line-height: 22px;
      color: #97a1a9;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #edeff1;
      span {
        flex: 1;
      }
    }
    .tableItem {
      display: flex;
      justify-content: space-between;
      margin-top: 27px;
      font-size: 16px;
      line-height: 24px;
      color: #151617;
      span {
        flex: 1;
      }
    }
  }
}
@media screen and (min-width: 415px) and (max-width: 765px) {
  .container {
    padding: 14% 20px 20px;
    .main {
      padding: 20px;
    }
    .title {
      margin:10px 0 10px 0;
      h1 {
        font-size: 14px;
        line-height: 30px;
      }
      div {
        font-size: 14px;
      }
    }
  }

  .table .item .right {
    line-height: 20px;
  }
}
@media screen and (min-width: 320px) and (max-width: 415px) {
.container {
    padding: 14% 20px 20px;
    .main {
      padding: 20px;
      margin-top: 20px;
    }
    .title {
      margin: 35px 0 10px 0;
      h1 {
        font-size: 14px;
        line-height: 30px;
        // color: #333;
      }
      div {
        font-size: 14px;
        // color: #333;
      }
    }
  }

  .table .item .right {
    line-height: 20px;
  }
}
</style>