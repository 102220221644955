import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/less/index.less'
import VueClipboard from 'vue-clipboard2'
import i18n from '@/locales/index.js'
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  i18n,
  router
}).$mount('#app')
