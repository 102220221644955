<template>
  <div class="container">
    <com-header />
    <p class="slogan">
      {{ $t("home_slogen") }}
    </p>
    <div class="carousel-warp" v-if="Array.isArray(bannerList) && bannerList.length > 0">
      <template>
        <el-carousel indicator-position="outside" :height="height">
          <el-carousel-item v-for="item in bannerList" :key="item._id">
            <a  style="cursor: pointer" target="_blank">
              <el-image :src="item.imageUrl" :fit="cover" @click="jumpToBannerLink(item)"></el-image>
            </a>
          </el-carousel-item>
        </el-carousel>
      </template>
    </div>
    <div v-if="bannerList.length === 0">
      <el-image :src="defaultImage"></el-image>
    </div>
    <div class="main">
      <div class="header">
        <span>{{ $t("home_recruitment") }}</span>
        <span class="filter">
          <el-input
            v-model="keywords"
            clearable
            @change="onBlurEvent"
            :placeholder="home_keyword_placeholder"
          ></el-input>
          <el-select
            v-model="filter.base"
            clearable
            :placeholder="home_select_base"
          >
            <el-option
              v-for="item in baseList"
              :key="item._id"
              :label="
                lang.startsWith('zh')
                  ? item.base
                  : item.baseEn
                  ? item.baseEn
                  : item.base
              "
              :value="
                lang.startsWith('zh')
                  ? item.base
                  : item.baseEn
                  ? item.baseEn
                  : item.base
              "
            >
            </el-option>
          </el-select>
          <el-select
            v-model="filter.department"
            clearable
            :placeholder="home_select_department"
          >
            <el-option
              v-for="item in departmentList"
              :key="item._id"
              :label="
                lang.startsWith('zh')
                  ? item.department
                  : item.departmentEn
                  ? item.departmentEn
                  : item.department
              "
              :value="
                lang.startsWith('zh')
                  ? item.department
                  : item.departmentEn
                  ? item.departmentEn
                  : item.department
              "
            >
            </el-option>
          </el-select>
        </span>
      </div>
      <div class="details">
        <div class="department" v-if="hotList.length > 0">
          <div class="title"><span></span>{{ $t("home_employment") }}</div>
          <div v-for="job in hotList" :key="job._id" class="jobList">
            <div class="item">
              <div class="left">
                <div class="jobName" @click="jumpToDetail(job._id)">
                  {{
                    lang.startsWith("zh")
                      ? job.jobName
                      : job.jobNameEn
                      ? job.jobNameEn
                      : job.jobName
                  }}
                  <span v-if="job.isHot" class="hot">{{
                    $t("home_employment")
                  }}</span>
                </div>
                <div class="base">
                  {{
                    lang.startsWith("zh")
                      ? job.jobBase
                      : job.jobBaseEn
                      ? job.jobBaseEn
                      : job.jobBase
                  }}
                </div>
              </div>
              <div class="right">
                <div>{{ $t("home_incentive") }}</div>
                <div>
                  {{
                    lang.startsWith("zh")
                      ? job.incentiveAmount
                      : job.incentiveAmountEn
                      ? job.incentiveAmountEn
                      : job.incentiveAmount
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="jobsList.length > 0">
          <div
            class="department"
            v-for="(department, key, index) in jobs"
            :key="index"
          >
            <div class="title">
              <span></span>{{ key == "undefined" ? "Other" : key }}
            </div>
            <div v-for="job in department" :key="job._id" class="jobList">
              <div class="item">
                <div class="left">
                  <div class="jobName" @click="jumpToDetail(job._id)">
                    {{
                      lang.startsWith("zh")
                        ? job.jobName
                        : job.jobNameEn
                        ? job.jobNameEn
                        : job.jobName
                    }}
                    <span v-if="job.isHot" class="hot">{{
                      $t("home_employment")
                    }}</span>
                  </div>
                  <div class="base">
                    {{
                      lang.startsWith("zh")
                        ? job.jobBase
                        : job.jobBaseEn
                        ? job.jobBaseEn
                        : job.jobBase
                    }}
                  </div>
                </div>
                <div class="right">
                  <div>{{ $t("home_incentive") }}</div>
                  <div>
                    {{
                      lang.startsWith("zh")
                        ? job.incentiveAmount
                        : job.incentiveAmountEn
                        ? job.incentiveAmountEn
                        : job.incentiveAmount
                    }} RMB
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-empty v-else :image-size="100"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getBaseAllList, getDepartmentAllList, getJobsList, getBannerList } from "@/api";
import header from "@/components/header.vue";
import { groupBy } from "lodash";
import { get as getLanguage } from "@/utils/config.js";
import defaultImage from "@/assets/img/home-default.png";

export default {
  data() {
    return {
      defaultImage,
      height: 300,
      baseList: [],
      departmentList: [],
      bannerList: [],
      jobs: {},
      jobsEn: {},
      keywords: '',
      jobsList: [],
      filter: {
        base: "",
        department: "",
        keywords: "",
        status: "2",
      },
      hotList: [],
      lang: getLanguage(),
      home_select_base: this.$t("home_select_base"),
      home_keyword_placeholder: this.$t("home_keyword_placeholder"),
      home_select_department: this.$t("home_select_department"),
    };
  },
  watch: {
    filter: {
      handler() {
        this.getJobsFun();
      },
      deep: true,
    },
  },
  methods: {
    onBlurEvent(){
      this.filter.keywords = this.keywords
    },
    /** 跳转 */
    jumpToBannerLink(row){
      if(row && row.linkUrl) {
        let type = row.openNewPage === '1' ? '_target' : '_self'
        window.open(row.linkUrl, type)
      }
    },
    /** 跳转详情 */
    jumpToDetail(id) {
      /** 本地和线上域名domain */
      const domain = {
          development: 'http://localhost:8080',
          production: 'https://www.renrenbl.com/',
      }
      /** 获取到环境变量 */
      const NODE_ENV = process.env.NODE_ENV || 'production';
      window.open(`${domain[NODE_ENV]}/#/detail?id=${id}`, '_blank')
    },
    getData() {
      getDepartmentAllList().then((res) => {
        if (res.code === 200) {
          this.departmentList = res.data;
        }
      });
      getBaseAllList().then((res) => {
        if (res.code === 200) {
          this.baseList = res.data;
        }
      });
      /** 获取banner配置 */
      getBannerList().then((res) => {
        if (res.code === 200) {
          this.bannerList = res.data;
        }
      });
      this.getJobsFun();
    },
    getJobsFun() {
      let data = {
        ...this.filter,
      };
      if (!this.lang.startsWith("zh")) {
        data = {};
        data.status = "2";
        data.departmentEn = this.filter.department;
        data.baseEn = this.filter.base;
        data.keywordsEn = this.filter.keywords;
      }
      getJobsList(this.filter).then((res) => {
        if (res.code === 200) {
          const { data } = res;
          this.jobsList = data;
          this.hotList = this.jobsList.filter((v) => v.isHot);
          this.jobs = groupBy(
            data,
            this.lang.startsWith("zh") ? "department" : "departmentEn"
          );
        }
      });
    },
  },
  components: {
    ComHeader: header,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.container {
  background-image: url(../assets/img/home-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 6% 120px 82px;
  .slogan {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    margin: 2% 0;
    color: #ffffff;
    text-align: center;
  }
  /** 轮播图 */
  .carousel-warp{
    .el-carousel{
      border-radius: 16px;
      // text-align: center;
      // margin: auto;
      .el-carousel__item {
        border-radius: 16px;
        width: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        text-align: center;
        .el-image {
          max-width: 100%;
          height: 100%;
          text-align: center;
        }
      }
    }
  }
  .main {
    padding: 40px;
    background: #ffffff;
    border-radius: 16px;
    .header {
      background-image: url(../assets/img/square.png);
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: left center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
      & > span:first-child {
        padding-left: 28px;
      }
      .filter {
        .el-input {
          width: 193px;
          margin-left: 20px;
        }
        .el-select {
          margin-left: 20px;
        }
      }
    }
    .details {
      .department {
        margin-bottom: 32px;
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #97a1a9;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          span {
            display: inline-block;
            margin-right: 8px;
            width: 6px;
            height: 6px;
            background: #a4adb3;
            transform: rotateZ(45deg);
          }
        }
        .jobList {
          background: #f7f8f9;
          border-radius: 8px;
          padding: 20px;
          .item {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .right {
              font-size: 14px;
              text-align: right;
              line-height: 25px;
              color: #f96363;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .jobName {
              font-size: 20px;
              line-height: 32px;
              color: #151617;
              cursor: pointer;
              display: flex;
              align-items: center;
              .hot {
                color: #f96363;
                font-size: 12px;
                border: 1px solid#f96363;
                border-radius: 4px;
                padding: 0px 6px;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                margin-left: 4px;
              }
            }
            .base {
              background-image: url(../assets/img/base.png);
              background-repeat: no-repeat;
              background-size: 10px;
              background-position: 2px center;
              font-size: 14px;
              line-height: 24px;
              color: #97a1a9;
              padding-left: 18px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 415px) and (max-width: 765px) {
  .container {
    padding: 17% 20px 20px;
    .main {
      padding: 20px;
    }
    .slogan{
      font-size: 22px !important;
      margin: 0 0 2% 0 !important;
    }
  }
  .container .slogan {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 7%;
  }
  .filter {
    .el-input {
      margin-bottom: 10px;
    }
    .el-select {
      margin-bottom: 10px;
    }
  }
  .department {
    .left {
      font-size: 12px !important;
      .jobName {
        font-size: 12px !important;
      }
      .base {
        font-size: 12px !important;
      }
    }
  }
  .carousel-warp{
    .el-carousel{
      height: 200px !important;
      .el-carousel__container{
        height: 200px !important;
        .el-carousel__item {
          border-radius: 16px;
          width: 100%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          text-align: center;
          .el-image {
            max-width: 100%;
            height: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 415px) {
  .container {
    padding: 17% 20px 20px;
    .main {
      padding: 20px;
    }
    .slogan{
      font-size: 20px !important;
      margin: 8% 0 3% 0 !important;
    }
  }
  .container .slogan {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 7%;
  }
  .filter {
    .el-input {
      margin-bottom: 10px;
    }
    .el-select {
      margin-bottom: 10px;
    }
  }
  .department {
    .left {
      font-size: 12px !important;
      .jobName {
        font-size: 12px !important;
      }
      .base {
        font-size: 12px !important;
      }
    }
  }

  .el-carousel{
    height: 150px !important;
    .el-carousel__container {
        height: 150px !important;
        .el-carousel__item {
        border-radius: 16px;
        width: 100%;
        text-align: center;
        .el-image {
          max-width: 100%;
          height: 100%;
          text-align: center;
        }
      }
    }
  }

}
</style>