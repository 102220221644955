<template>
  <div class="container">
    <com-header />
    <div class="main">
      <h1>{{ $t("rule_message_1") }}</h1>
      <p class="text">
        {{ $t("rule_message_2") }}
      </p>
      <br />
      <p class="text">
        {{ $t("rule_message_3") }}
      </p>
      <br />
      <p class="text">{{ $t("rule_message_4") }}</p>
      <p class="text">
        {{ $t("rule_message_5") }}
      </p>
      <p class="text">{{ $t("rule_message_6") }}</p>
      <p class="text2">
        {{ $t("rule_message_7") }}
      </p>
      <p class="text2">
        {{ $t("rule_message_8") }}
      </p>
      <p class="text3">{{ $t("rule_message_9") }}</p>
      <p class="text3">{{ $t("rule_message_10") }}</p>
      <p class="text3">
        {{ $t("rule_message_11") }}
      </p>
      <p class="text2">{{ $t("rule_message_12") }}</p>
      <p class="text3">
        {{ $t("rule_message_13") }}
      </p>
      <p class="text3">{{ $t("rule_message_14") }}</p>
      <p class="text2">{{ $t("rule_message_15") }}</p>
      <div class="table">
        <div class="tableHeader">
          <span>{{ $t("rule_message_16") }}</span>
          <span>{{ $t("rule_message_17") }}</span>
          <span>{{ $t("rule_message_18") }}</span>
        </div>
        <div class="item">
          <span class="left">{{ $t("rule_message_19") }}</span>
          <span class="mid">{{ $t("rule_message_20") }}</span>
          <span class="right">{{ $t("rule_message_21") }}</span>
        </div>
        <div class="item">
          <span class="left">{{ $t("rule_message_22") }}</span>
          <span class="mid">{{ $t("rule_message_23") }}</span>
          <span class="right">{{ $t("rule_message_24") }}</span>
        </div>
        <div class="item">
          <span class="left">{{ $t("rule_message_25") }}</span>
          <span class="mid">{{ $t("rule_message_26") }}</span>
          <span class="right">{{ $t("rule_message_27") }}</span>
        </div>
      </div>
      <p class="text">{{ $t("rule_message_28") }}</p>
      <p class="text2">
        {{ $t("rule_message_29")
        }}<a href="https://www.renrenbl.com/">www.renrenbl.com/</a>
      </p>
      <p class="text2">{{$t("rule_message_30")}}renrenbole01@gmail.com</p>
      <p class="text">{{$t("rule_message_31")}}</p>
      <p class="text2">{{$t("rule_message_32")}}</p>
      <p class="text2">{{$t("rule_message_33")}}</p>
      <p class="text">{{$t("rule_message_34")}}</p>
      <p class="text2">{{$t("rule_message_35")}}</p>
      <p class="text">
        {{$t("rule_message_36")}}
      </p>
    </div>
  </div>
</template>

<script>
import header from "@/components/header.vue";
export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    ComHeader: header,
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.container {
  background-image: url(../assets/img/detail-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 8% 120px 82px;
  .main {
    position: relative;
    padding: 40px;
    background: #ffffff;
    border-radius: 16px;
    h1 {
      text-align: center;
      line-height: 32px;
    }
    .text {
      font-size: 14px;
      line-height: 28px;
      text-indent: 28px;
    }
    .text2 {
      font-size: 14px;
      line-height: 28px;
      text-indent: 40px;
    }
    .text3 {
      font-size: 14px;
      line-height: 28px;
      text-indent: 54px;
    }
  }
}
.table {
  margin-top: 12px;
  border: 1px solid #eaecef;
  border-radius: 4px;
  .tableHeader {
    background: #f7f8f9;
    display: flex;
    height: 40px;
    span {
      flex: 1;
      text-align: center;
      font-size: 14px;
      line-height: 40px;
      color: #97a1a9;
    }
  }
  .item {
    display: flex;
    height: 90px;
    border-bottom: 1px solid #f4f5f7;
    font-size: 14px;
    &:last-child {
      border-bottom: none;
    }
    span {
      flex: 1;
      line-height: 40px;
      text-align: center;
    }
    .left {
      border-right: 1px solid #f4f5f7;
      line-height: 90px;
    }
    .mid {
      color: #2ebc84;
      border-right: 1px solid #f4f5f7;
      line-height: 90px;
    }
    .right {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 765px) {
  .container {
    padding: 17% 20px 20px;
    .main {
      padding: 20px;
    }
  }

  .table .item .right {
    line-height: 20px;
  }
}
</style>