<template>
  <div>
    <header>
      <span class="logo" @click="$router.push('/home')">
        <img src="../assets/img/logo.svg" alt="" />
      </span>
      <span class="link">
        <router-link to="/" :class="route === '/home' && 'active'">{{
          $t("home_home")
        }}</router-link>
        <router-link to="/rank" :class="route === '/rank' && 'active'">{{
          $t("home_rank")
        }}</router-link>
        <span class="home_recommend__warp" @click="recommend">{{$t("home_recommend_ways")}}</span>
        <el-select
          class="language"
          style="width: 100px"
          size="mini"
          v-model="lang"
          @change="changeLang"
          placeholder="请选择语言"
        >
          <el-option label="简体中文" value="zh_CN"> </el-option>
          <el-option label="English" value="en_US"> </el-option>
        </el-select>
      </span>
    </header>
    <RecommendModal :visible="visible" v-if="visible" @changeModalVisible='changeModalVisible' />
  </div>
</template>

<script>
import { get as getLanguage, set as setLanguage } from "@/utils/config.js";
import recommendModal from './recommendModal.vue';
export default {
  computed: {
    route() {
      return this.$route.path;
    },
  },
  data() {
    return {
      lang: getLanguage(),
      langMap: {
        zh_CN: "简体中文",
        en_US: "英文",
      },
      visible: false
    };
  },
  methods: {
    changeLang(e) {
      setLanguage(e);
      location.reload();
    },
    recommend() {
      this.visible = true;
    },
    changeModalVisible(data) {
      this.visible = data;
    },
  },
  components: {
    RecommendModal: recommendModal,
  },
};
</script>

<style lang="less" scoped>
header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 84px);
  padding: 34px 42px;
  .logo {
    display: inline-block;
    width: 128px;
    height: 44px;
    cursor: pointer;
    img {
      width: 300px;
      height: 300px;
      position: absolute;
      left: -50px;
      top: -106px;
    }
  }
  .link {
    display: flex;
    align-items: center;
    height: 28px;
    a,
    span {
      font-weight: 500;
      margin-left: 36px;
      font-size: 16px;
      color: #ffffff;
    }
    .active {
      color: #2ebc84;
    }
    .el-select {
      margin-left: 36px;
    }
    .home_recommend__warp{
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 565px) and (max-width: 765px) {
  header {
    padding: 34px 32px;
    width: calc(100% - 64px);
    .link {
      a,span {
        margin-left: 5px;
        // font-size: 14px;
      }
      .el-select {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 565px) {
  header {
    display: block;
    padding: 20px 0;
    width: 100%;
    .logo {
      display: inline-block;
      width: 100%;
      height: 44px;
      cursor: pointer;
      img {
        width: 300px;
        height: 300px;
        position: absolute;
        left: 50%;
        top: -120px;
        margin-left: -150px;
      }
    }
    .link {
      display: flex;
      align-items: center;
      height: 28px;
      margin-top: -15px;
      a, .home_recommend__warp, .language{
        flex: 1;
        z-index: 10;
      }
      a,
      span {
        font-weight: 500;
        margin-left: 10px;
        font-size: 14px;
        color: #ffffff;
      }
      .active {
        color: #2ebc84;
      }
      .el-select {
        margin: 0 5px 0px 0px;
      }
      .home_recommend__warp{
        cursor: pointer;
      }
    }
  }
}
</style>