<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { set as setLanguage } from "@/utils/config.js";
export default {
  name: "App",
  methods: {},
  mounted() {
    if (this.$route.query.lang) {
      setLanguage(this.$route.query.lang);
      let query = {...this.$route.query}
      delete query.lang
      this.$router.push({
        path: this.$route.path,
        query,
      });
      location.reload()
      //
    }
  },
};
</script>

<style lang='less'>
#app {
  height: 100%;
}
.el-dialog__header {
  border-bottom: 1px solid #f7f7f7;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-select .el-input__inner:focus {
  border-color: #2ebc84 !important;
}
</style>