import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/home'
import rank from '@/views/rank'
import detail from '@/views/detail'
import rule from '@/views/rule'
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: '首页',
            redirect: '/home'
        },
        {
            path: '/home',
            name: '首页',
            component: home
        },
        {
            path: '/rank',
            name: '伯乐排行榜',
            component: rank
        },
        {
            path: '/detail',
            name: '详情',
            component: detail
        },
        {
            path: '/rule',
            name: '规则',
            component: rule
        },
    ]
})
